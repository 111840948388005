<script>
	import Base from '@backend/Base.vue';
	import draggable from 'vuedraggable'
	import BOGen from '@helper/BOGen';
	export default {
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "Home",
				PageName: "Home",
				about: {},
				imageInput: {},
				adv: [],
				data: [],
				show_banner: {},
				show_popular: {},
				show_exclusive: {},
				show_blog: [],
				show_brand: [],
				stimage: [],
				partner: {},
				showButton:false
			}
		},
		computed: {

		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data,
					type: 'sortSlider'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			submitFormSlider(e, callback) {
				if (e && e.btnLoading()) return;
				this.row.as_project = 0
				BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger")
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseSlider"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000).then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			addSlide() {
				
				this.row = {}
				this.row = {
					as_is_active: "Y",
					as_have_button: "N",
					as_show_text: "Y",
					as_slide_position: "slider-caption-center",
				}
				this.row.type = 'add'
			},
			editSlide(v) {
				$('#collapseSlider').addClass('show')
				this.row = v;
				this.row.type = 'update'
			},
			deleteItemId(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delSlider",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
			submitAbout(e, callback) {
				if (e && e.btnLoading()) return;
				this.about.sc_project = 0
				this.about.type = 'editAbout'
				BOGen.apirest("/" + this.Name, this.about, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-about')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-about').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			editStaticImage(v) {
				$('#collapseAdv').addClass('show')
				this.imageInput = v
				this.imageInput.type = 'editStaticImage'
			},
			submitFormImage(e, callback) {
				if (e && e.btnLoading()) return;
				this.imageInput.mpa_project = App.$route.params.id
				BOGen.apirest("/" + this.Name, this.imageInput, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-adv')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							$('[href="#collapseAdv"]').click();
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-adv').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			deleteAdv(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "deleteAdv",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
		},
		watch: {
			'show_banner.wc_is_active'(v) {
				if (v == true) {
					this.show_banner.wc_is_active = 'Y'
					this.show_bannerCheck = false
				} else if (v == false) {
					this.show_banner.wc_is_active = 'N'
					this.show_bannerCheck = true
				}
				this.show_banner.type = "showBanner"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.show_banner, (err, resp) => {}, "POST")
				}, 2000)
			},
			'show_popular.wc_is_active'(v) {
				if (v == true) {
					this.show_popular.wc_is_active = 'Y'
				} else if (v == false) {
					this.show_popular.wc_is_active = 'N'
				}
				this.show_popular.type = "showPopular"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.show_popular, (err, resp) => {}, "POST")
				}, 2000)

			},
			'show_exclusive.wc_is_active'(v) {
				if (v == true) {
					this.show_exclusive.wc_is_active = 'Y'
				} else if (v == false) {
					this.show_exclusive.wc_is_active = 'N'
				}
				this.show_exclusive.type = "showExclusive"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.show_exclusive, (err, resp) => {}, "POST")
				}, 2000)

			},
			'show_blog.wc_is_active'(v) {
				if (v == true) {
					this.show_blog.wc_is_active = 'Y'
				} else if (v == false) {
					this.show_blog.wc_is_active = 'N'
				}
				this.show_blog.type = "showBlog"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.show_blog, (err, resp) => {}, "POST")
				}, 2000)

			},
			'show_brand.wc_is_active'(v) {
				if (v == true) {
					this.show_brand.wc_is_active = 'Y'
				} else if (v == false) {
					this.show_brand.wc_is_active = 'N'
				}
				this.show_brand.type = "showBrand"
				setTimeout(() => {
					BOGen.apirest("/" + this.Name, this.show_brand, (err, resp) => {}, "POST")
				}, 2000)

			}
		}

	};
</script>
<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row mt-2">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Slider Image</h5>
						<draggable v-model="data.data" class="row" tag="tbody" @end="endDrag">
							<div style="cursor:move;" class="col-md-3" :id="'dat'+v.as_id" v-for="(v,k) in data.data" :key="k">
								<div class="wrap_slider_img">
									<img :src="uploader(v.as_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{v.as_title}}</p>
									</div>
									<a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteItemId(v.as_id,'dat'+v.as_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="v.as_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-3">
								<a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseSlider" role="button"
									aria-expanded="false" aria-controls="collapseSlider">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Slider</p>
									</div>
								</a>
							</div>
						</draggable>
						<VForm @resp="submitFormSlider" method="post">
							<div class="row collapse mt-4" id="collapseSlider">
								<div class="col-12 info"></div>
								<div class="col-md-4 mb-3">
									<BoField name="as_img">
										<Uploader name="as_img" :param="{thumbnail:true}" :attr="{required:1}" v-model="row.as_img" type="slideshow"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Heading</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="as_title" v-model="row.as_title" :help="'Maximal 100 characters.'"></BoField>
														</div>
														<div class="col-md-12">
															<BoField name="as_desc" :help="'Maximal 255 character.'">
																<textarea  name="as_desc" v-bind="validation('as_desc')" v-model="row.as_desc" rows="4" class="form-control"></textarea>
															</BoField>															
															<BoField name="as_have_button">
																<div class="row">
																	<radio name="as_have_button" v-model="row.as_have_button" option="Y"
																		:attr="validation('as_have_button')">
																		Yes</radio>
																	<radio name="as_have_button" v-model="row.as_have_button" option="N">
																		No</radio>
																	</div>
															</BoField>
														</div>

														<div class="col-md-9" v-if="row.as_have_button=='Y'">
															<BoField name="as_btn_label" v-model="row.as_btn_label"></BoField>
															<BoField name="as_btn_link" :attr="{type:'url'}" v-model="row.as_btn_link"></BoField>
														</div>

														<div class="col-md-9">
															<BoField name="as_is_active">
																<div class="row">
																	<radio name="as_is_active" v-model="row.as_is_active" option="Y"
																		:attr="validation('as_is_active')">
																		Active</radio>
																	<radio name="as_is_active" v-model="row.as_is_active" option="N">
																		Inactive</radio>
																</div>
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12 mt-3 text-right">
											<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit Slider</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
				<!-- End Slider -->

				<!-- Start About -->
				<div class="row">
					<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title">About Us</h5>
								<VForm @resp="submitAbout" method="post">
									<div class="row">
										<div class="col-md-12 info-about"></div>
										<div class="col-md-8 max_120">
											<BoField name="sc_title" :attr="{minlength:3,maxlength:100}" v-model="about.sc_title" mandatory :help="'Maximal 100 characters'"></BoField>
											<BoField name="sc_desc" mandatory>
												<CKEditor name="content" class="form-control" v-model="about.sc_desc"></CKEditor>
											</BoField>
										</div>
										<div class="col-md-4">
											<BoField name="sc_background" mandatory :label="'Image'">
												<Uploader :param="{thumbnail:true}" name="sc_background" v-model="about.sc_background" type="logo" uploadType="crop"></Uploader>
											</BoField>
											<input type="hidden" v-model="about.sc_id">
										</div>
										<div class="col-md-12 text-right"><button type="submit"	class="btn btn-rounded btn-info btn-loading">Update About Home</button></div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
				<!-- End About -->

				<!-- Static Image -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Banner</h5>
						<div class="row mt-4" tag="tbody">
							<div style="cursor:move;" class="col-md-2" :id="'dat'+va.as_id" v-for="(va,ka) in stimage" :key="ka">
								<div class="wrap_slider_img">
									<img :src="uploader(va.asi_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{va.asi_title}}</p>
									</div>
									<a data-toggle="collapse" @click="editStaticImage(va)" href="javascript:;" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
								</div>
							</div>
						</div>
						<VForm @resp="submitFormImage" method="post">
							<div class="row collapse mt-4" id="collapseAdv">
								<div class="col-12 info-adv"></div>
								<div class="col-md-4 mb-3">
									<BoField name="asi_img" mandatory v-if="imageInput.asi_ratio == 'banner'">
										<Uploader :param="{thumbnail:true}" name="asi_img" v-model="imageInput.asi_img" type="banner" uploadType="Upload"></Uploader>
									</BoField>
									<BoField name="asi_img" mandatory v-else>
										<Uploader :param="{thumbnail:true}" name="asi_img" v-model="imageInput.asi_img" type="product" uploadType="Upload"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Content</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-9">
															<BoField name="asi_title" :attr="{minlength:3,maxlength:100}" v-model="imageInput.asi_title" mandatory>
															</BoField>
														</div>
														<div class="col-md-9">
															<BoField name="asi_desc" :help="'Maximal 100 characters.'">
																<textarea maxlength="100" v-model="imageInput.asi_desc" rows="4" class="form-control"></textarea>
															</BoField>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12 mt-3 text-right">
											<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit Data</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
				<!-- End Static Image -->
			</div>
			<div class="col-md-4">

				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Banner</h5>
						<div class="row">
							<div class="col-md-4 max_120">
								Show Section?
							</div>
							<div class="col-md-8">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<div class="row">
											<radio name="wc_banner" v-model="show_banner.wc_is_active" option="Y" :attr="validation('wc_is_active')">
												Yes</radio>
											<radio name="wc_banner" v-model="show_banner.wc_is_active" option="N">
												No</radio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End Show Unit -->
			</div>
			<div class="col-md-4">
				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Most Popular</h5>
						<div class="row">
							<div class="col-md-4 max_120">
								Show Section?
							</div>
							<div class="col-md-8">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<div class="row">
											<radio name="wc_popular" v-model="show_popular.wc_is_active" option="Y" :attr="validation('wc_is_active')">
												Yes</radio>
											<radio name="wc_popular" v-model="show_popular.wc_is_active" option="N">
												No</radio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End Show Unit -->
			</div>
			<div class="col-md-4">
				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Exclusive</h5>
						<div class="row">
							<div class="col-md-4 max_120">
								Show Section?
							</div>
							<div class="col-md-8">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<div class="row">
											<radio name="wc_exclusive" v-model="show_exclusive.wc_is_active" option="Y" :attr="validation('wc_is_active')">
												Yes</radio>
											<radio name="wc_exclusive" v-model="show_exclusive.wc_is_active" option="N">
												No</radio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End Show Unit -->
			</div>
			<div class="col-md-4">
				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show latest Blog</h5>
						<div class="row">
							<div class="col-md-4 max_120">
								Show Section?
							</div>
							<div class="col-md-8">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<div class="row">
											<radio name="wc_blog" v-model="show_blog.wc_is_active" option="Y" :attr="validation('wc_is_active')">
												Yes</radio>
											<radio name="wc_blog" v-model="show_blog.wc_is_active" option="N">
												No</radio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End Show Unit -->
			</div>
			<div class="col-md-4">
				<!-- Show Unit -->
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Show Brand</h5>
						<div class="row">
							<div class="col-md-4 max_120">
								Show Section?
							</div>
							<div class="col-md-8">
								<div class="custom-control custom-checkbox mr-sm-2 mb-3">
									<div class="row">
											<radio name="wc_brand" v-model="show_brand.wc_is_active" option="Y" :attr="validation('wc_is_active')">
												Yes</radio>
											<radio name="wc_brand" v-model="show_brand.wc_is_active" option="N">
												No</radio>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End Show Unit -->
			</div>
		</div>
	</div>
</template>